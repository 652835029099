<template>
  <!-- Modal start -->
  <section>
    <b-modal
      ref="editItemModal"
      id="editItemModal"
      hide-footer
      title="Add Item"
    >
      <div>
        <b-alert v-if="itemModalError" show variant="danger">{{
          itemModalError
        }}</b-alert>
      </div>
      <div v-if="isLoading" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <form @submit="onSubmit" v-else>
        <b-form-input hidden v-model="itemId" readonly></b-form-input>
        <b-container fluid>
          <b-row class="my-1 mt-3 mb-3" v-if="!selectedItem">
            <b-col>
              <b-form-group>
                <Add-Item-Input @update-item="updateItem"></Add-Item-Input>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Item -->
          <b-row class="my-1" v-else>
            <b-col sm="3">
              <b-form-group>
                <label for="input-none">Item</label>
              </b-form-group>
            </b-col>
            <b-col sm="8" cols="10">
              <b-form-group>
                <b-form-input
                  v-model="orderLine.name"
                  required
                  readonly
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col sm="1" cols="1">
              <b-button variant="danger" @click="removeItem('item')"
                >x</b-button
              >
            </b-col>
          </b-row>

          <!-- Wall nr start -->
          <b-row class="my-1" v-if="selectedItem">
            <b-col sm="3">
              <b-form-group>
                <label for="input-none">Wall nr.</label>
              </b-form-group>
            </b-col>
            <b-col sm="8">
              <b-form-group>
                <b-form-input
                  v-model="orderLine.description"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Wall nr end -->

          <!-- quantity -->
          <b-row class="my-1 mt-1" v-if="selectedItem">
            <b-col sm="3">
              <label class="mt-2" for="input-invalid">Quantity</label>
            </b-col>

            <b-col sm="8" v-if="customQty">
              <b-form-input
                id="range-2"
                v-model="qty"
                required
                type="number"
              ></b-form-input>
            </b-col>
            <b-col v-else>
              <b-button
                variant="success"
                class="mr-2 "
                size="lg"
                @click="setQty(1)"
                >1</b-button
              >
              <b-button
                variant="success"
                class="mr-2"
                size="lg"
                @click="setQty(2)"
                >2</b-button
              >
              <b-button
                variant="success"
                class="mr-2"
                size="lg"
                @click="setQty(3)"
                >3</b-button
              >
              <b-button
                variant="success"
                class="mr-2"
                size="lg"
                @click="setQty(4)"
                >4</b-button
              >
            </b-col>
          </b-row>
          <!-- quantity end -->

          <!-- Dimensions -->
          <b-row class="my-1 mt-4" v-if="selectedItem">
            <b-col sm="3">
              <label class="mt-2">Dimensions:</label>
            </b-col>
            <b-col sm="4">
              <b-form-input
                id="input-invalid"
                type="number"
                class="mt-1"
                required
                v-model="height"
                step="any"
                placeholder="Enter height"
              ></b-form-input>
            </b-col>
            <b-col sm="4">
              <b-form-group class="mt-1">
                <b-form-input
                  id="input-valid"
                  v-model="width"
                  required
                  type="number"
                  step="any"
                  placeholder="Enter width"
                ></b-form-input>
              </b-form-group>
              <b-form-input
                id="input-invalid"
                step="any"
                hidden
                type="number"
                v-model="orderLine.sqrMeter"
                readonly
              ></b-form-input>
            </b-col>
          </b-row>
          <!-- Dimensions end -->

          <!-- Polish type -->
          <b-row
            class="my-1"
            v-if="
              getCurrentItem.polishType &&
                getCurrentItem.polishType.length > 0 &&
                selectedItem
            "
          >
            <b-col sm="3">
              <label for="polisingTypes">Polishing</label>
            </b-col>
            <b-col sm="8" cols="10">
              <b-form-select
                v-model="orderLine.polishType"
                :options="polishTypeOptions"
              ></b-form-select>
            </b-col>
            <b-col sm="1" cols="1">
              <b-button
                variant="danger"
                class="mt-1"
                @click="removeItem('polishType')"
                v-if="hasSelectedPolishType"
                >x</b-button
              >
            </b-col>
          </b-row>
          <!-- Polish type end -->

          <!-- Special txt checkbox -->
          <b-row
            v-if="
              getCurrentItem.specialTxt &&
                getCurrentItem.specialTxt.length > 0 &&
                selectedItem &&
                !addSpecialTxt
            "
            class="mt-3"
          >
            <b-col>
              <b-form-checkbox
                id="checkbox-1"
                v-model="addSpecialTxt"
                name="checkbox-1"
                value="false"
                unchecked-value="true"
              >
                Add special txt details.
              </b-form-checkbox>
            </b-col>
          </b-row>
          <!-- Special txt checkbox end -->

          <!-- Special txt -->
          <b-row
            class="my-1 mt-3"
            v-if="
              getCurrentItem.specialTxt &&
                getCurrentItem.specialTxt.length > 0 &&
                selectedItem &&
                addSpecialTxt
            "
          >
            <b-col sm="3">
              <b-form-group>
                <label for="specialTxtTypes">Special TXT</label>
              </b-form-group>
            </b-col>
            <b-col sm="8" cols="10">
              <b-form-group>
                <b-form-select
                  v-model="orderLine.specialTxt"
                  :options="specialTxtOptions"
                ></b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="1" cols="1">
              <b-button
                variant="danger"
                class="mt-1"
                @click="removeItem('specialTxt')"
                v-if="hasSelectedSpecialTxt"
                >x</b-button
              >
            </b-col>
          </b-row>
          <!-- Special txt end -->

          <Upload-Image
            :orderLine="orderLine"
            :selectedItem="selectedItem"
          ></Upload-Image>

          <b-row>
            <b-button variant="primary" type="submit" class="m-3"
              >Update</b-button
            >
            <b-button variant="danger" @click="hideModal" class="m-3"
              >Cancel</b-button
            >
          </b-row>
        </b-container>
      </form>
    </b-modal>
  </section>
  <!-- Modal END -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import config from "@/store/config.js";
import UploadImage from "@/components/UploadImage";

export default {
  name: "editItemModal",
  components: {
    UploadImage
  },
  data() {
    return {
      isLoading: true,
      query: "",
      itemModalError: null,
      addSpecialTxtValue: false,
      orderLine: {
        id: null,
        name: null,
        qty: 0,
        height: null,
        width: null,
        sqrMeter: null,
        description: "",
        specialTxt: null,
        polishType: null,
        attachmentUrl: null,
        AttachmentUrl2: null,
        AttachmentUrl3: null,
        AttachmentUrl4: null
      }
    };
  },
  mounted() {
    this.loadItems();
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "updateCreateCurrentOrder",
      "createNewOrderOnProject",
      "setCurrentOrderLine",
      "loadItems",
      "setNewFormItem"
    ]),
    setQty(qty) {
      this.orderLine.qty = qty;
    },
    uploadFile() {
      this.file = this.$refs.file.files[0];
      if (this.file) {
        let files = new FormData();
        files.append("file", this.file);

        axios
          .post(`${config.API}/attachment/${this.getCurrentOrder.id}`, files, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then(response => {
            this.orderLine.attachmentUrl = response.data;
            self.error = null;
          })
          .catch(error => {
            self.error = error;
          });
      }
    },
    hideModal() {
      //validates that all the required fields are added
      this.$bvModal.hide("editItemModal");
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["editItemModal"].toggle("#toggle-btn");
    },
    updateSquare() {
      if (
        this.orderLine.height > 0 &&
        this.orderLine.height > 0 &&
        this.orderLine.qty > 0
      ) {
        this.orderLine.sqrMeter =
          (this.orderLine.qty *
            (this.orderLine.height * this.orderLine.width)) /
          1000000;
      }
    },
    removeItem(name) {
      if (name === "polishType") {
        this.orderLine.polishType = "";
      } else if (name === "specialTxt") {
        this.orderLine.specialTxt = "";
      } else if (name === "item") {
        this.orderLine.name = "";
        this.orderLine.specialTxt = "";
        this.orderLine.polishType = "";
      }
    },
    updateItem(data) {
      this.isLoading = true;
      this.orderLine.specialTxt = null;
      this.orderLine.polishType = null;
      this.setNewFormItem(data.id).then(() => {
        this.orderLine.name = this.getCurrentItem.name;
        this.isLoading = false;
      });
      this.orderLine.itemId = data.id;
    },
    onSubmit(evt) {
      evt.preventDefault();
      window.scrollTo(0, 0);

      if (this.orderLine.id) {
        this.$set(
          this.getCurrentOrder.orderLines,
          this.getCurrentOrder.orderLines.findIndex(
            l => l.id === this.orderLine.id
          ), //index
          this.orderLine
        );
        this.updateCreateCurrentOrder(this.getCurrentOrder)
          .then(() => {
            this.$bvModal.hide("editItemModal");
          })
          .catch(e => {
            this.itemModalError = e;
          });
      } else {
        this.itemModalError = "Missing index!";
      }
    }
  },
  computed: {
    ...mapGetters([
      "getAllItems",
      "getEditorOrderLineId",
      "getCurrentProject",
      "getCurrentOrder",
      "getCurrentOrderLine",
      "getCurrentItem"
    ]),
    height: {
      get() {
        return this.orderLine.height;
      },
      set(value) {
        this.orderLine.height = value;
      }
    },
    width: {
      get() {
        return this.orderLine.width;
      },
      set(value) {
        this.orderLine.width = value;
      }
    },
    qty: {
      get() {
        return this.orderLine.qty;
      },
      set(value) {
        this.orderLine.qty = value;
      }
    },
    selectedItem: {
      get() {
        return this.orderLine.name;
      }
    },
    addSpecialTxt: {
      get() {
        return this.addSpecialTxtValue;
      },
      set(value) {
        this.addSpecialTxtValue = value;
      }
    },
    customQty: {
      get() {
        return this.orderLine.qty > 0 ? true : false;
      }
    },
    itemId: {
      get() {
        return this.orderLine.itemId;
      },
      set(value) {
        this.orderLine.itemId = value;
      }
    },
    specialTxtOptions: {
      get() {
        if (this.getCurrentItem.specialTxt) {
          return this.getCurrentItem.specialTxt.map(x => {
            return { value: x.name, text: x.name };
          });
        } else {
          return null;
        }
      }
    },
    polishTypeOptions: {
      get() {
        if (this.getCurrentItem.polishType) {
          return this.getCurrentItem.polishType.map(x => {
            return { value: x.name, text: x.name };
          });
        } else {
          return null;
        }
      }
    },
    hasSelectedSpecialTxt: {
      get() {
        return this.orderLine.specialTxt;
      }
    },
    hasSelectedPolishType: {
      get() {
        return this.orderLine.polishType;
      }
    },
    lineId: {
      get() {
        return this.getEditorOrderLineId;
      }
    }
  },
  watch: {
    height() {
      this.updateSquare();
    },
    width() {
      this.updateSquare();
    },
    qty() {
      this.updateSquare();
    },
    itemId() {
      if (
        this.orderLine.itemId &&
        this.orderLine.itemId !== "00000000-0000-0000-0000-000000000000"
      ) {
        this.setNewFormItem(this.orderLine.itemId);
      } else {
        this.setNewFormItem();
      }
    },
    lineId() {
      if (this.getEditorOrderLineId && this.getEditorOrderLineId !== "-1") {
        this.orderLine = this.getCurrentOrderLine;
      } else {
        this.orderLine.id = null;
        delete this.orderLine.id;
        this.orderLine.qty = 0;
        this.orderLine.height = 0;
        this.orderLine.width = 0;
        this.orderLine.sqrMeter = 0;
        this.orderLine.description = "";
        this.orderLine.specialTxt = "";
        this.orderLine.polishType = "";
        // context.commit('setOrderLine',orderLine);
      }
    }
  }
};
</script>

<style></style>
