<template>
  <div>
    <b-row v-if="selectedItem && isUploading" class="mt-2 text-center">
      <b-col>
        <b-spinner label="uploading..."></b-spinner>
      </b-col>
    </b-row>

    <b-row v-if="selectedItem && canUpload && !isUploading" class="mt-2">
      <b-col sm="3">
        <label class="mt-2">Attachment: (max 10MB)</label>
      </b-col>
      <b-col sm="8">
        <div class="custom-file">
          <input
            type="file"
            accept=".PDF,.PNG,.JPG,.JPEG,.DXF,.DWG,.SKP,.doc,.docx,application/vnd.ms-excel,application/msword;image/*;capture=camera"
            name="file"
            ref="file"
            id="customFile"
            class="custom-file-input"
            @change="fileChange($event.target.files)"
          />
          <label class="custom-file-label" for="customFile">Choose file</label>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2 right">
      <b-col>
        <b-list-group>
          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-if="orderLine.attachmentUrl"
          >
            <a :href="this.orderLine.attachmentUrl" target="_blank">{{
              this.orderLine.attachmentUrl.substring(
                this.orderLine.attachmentUrl.lastIndexOf("/") + 1
              )
            }}</a>
            <a @click="removeAttachment(1)"><i class="fas fa-trash-alt"></i></a>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-if="orderLine.attachmentUrl2"
          >
            <a :href="this.orderLine.attachmentUrl2" target="_blank">{{
              this.orderLine.attachmentUrl2.substring(
                this.orderLine.attachmentUrl2.lastIndexOf("/") + 1
              )
            }}</a>
            <a @click="removeAttachment(2)"><i class="fas fa-trash-alt"></i></a>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-if="orderLine.attachmentUrl3"
          >
            <a :href="this.orderLine.attachmentUrl3" target="_blank">{{
              this.orderLine.attachmentUrl3.substring(
                this.orderLine.attachmentUrl3.lastIndexOf("/") + 1
              )
            }}</a>
            <!-- <b-badge variant="primary" pill>14</b-badge> -->
            <a @click="removeAttachment(3)"><i class="fas fa-trash-alt"></i></a>
          </b-list-group-item>

          <b-list-group-item
            class="d-flex justify-content-between align-items-center"
            v-if="orderLine.attachmentUrl4"
          >
            <a :href="this.orderLine.attachmentUrl4" target="_blank">{{
              this.orderLine.attachmentUrl4.substring(
                this.orderLine.attachmentUrl4.lastIndexOf("/") + 1
              )
            }}</a>
            <!-- <b-badge variant="primary" pill>14</b-badge> -->
            <a @click="removeAttachment(4)"><i class="fas fa-trash-alt"></i></a>
          </b-list-group-item>
        </b-list-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UploadImage",
  props: ["orderLine", "selectedItem"],
  data() {
    return {
      error: null,
      isUploading: false
    };
  },
  computed: {
    ...mapGetters(["getUploadError", "getCurrentOrder"]),
    canUpload: {
      get() {
        return (
          this.orderLine.attachmentUrl === null ||
          this.orderLine.attachmentUrl2 === null ||
          this.orderLine.attachmentUrl3 === null ||
          this.orderLine.attachmentUrl4 === null
        );
      }
    }
  },
  methods: {
    ...mapActions(["uploadFile"]),

    fileChange() {
      var file = this.$refs.file.files[0];
      var orderLineNumber = 0;
      //orderLine
      if (this.orderLine.attachmentUrl === null) {
        orderLineNumber = 1;
      } else if (this.orderLine.attachmentUrl2 === null) {
        orderLineNumber = 2;
      } else if (this.orderLine.attachmentUrl3 === null) {
        orderLineNumber = 3;
      } else if (this.orderLine.attachmentUrl4 === null) {
        orderLineNumber = 4;
      }

      if (orderLineNumber != 0) {
        this.isUploading = true;
        var orderId = this.getCurrentOrder.id;
        var self = this;
        this.uploadFile({ file, orderId })
          .then(r => {
            if (orderLineNumber === 1) {
              self.orderLine.attachmentUrl = r.data;
            } else if (orderLineNumber === 2) {
              self.orderLine.attachmentUrl2 = r.data;
            } else if (orderLineNumber === 3) {
              self.orderLine.attachmentUrl3 = r.data;
            } else if (orderLineNumber === 4) {
              self.orderLine.attachmentUrl4 = r.data;
            }
            self.isUploading = false;
          })
          .catch(e => {
            self.isUploading = false;
            self.error = e;
          });
      }
    },

    removeAttachment(nr) {
      if (nr) {
        if (nr === 1) {
          this.orderLine.attachmentUrl = null;
        } else if (nr === 2) {
          this.orderLine.attachmentUrl2 = null;
        } else if (nr === 3) {
          this.orderLine.attachmentUrl3 = null;
        } else if (nr === 4) {
          this.orderLine.attachmentUrl4 = null;
        }
      }
    }
  }
};
</script>

<style></style>
