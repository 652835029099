<template>
  <!-- Modal start -->
  <section>
    <b-modal ref="addItemModal" id="addItemModal" hide-footer title="Add Item">
      <div>
        <b-card v-if="getAppSettings.debug">
          <pre>
                  {{ this.orderLine }}
          </pre>
        </b-card>
        <b-alert v-if="itemModalError" show variant="danger">{{
          itemModalError
        }}</b-alert>
        <b-alert v-if="getUploadError" show variant="danger">{{
          getUploadError
        }}</b-alert>
        <b-alert v-if="errormsg" show variant="danger">{{ errormsg }}</b-alert>
      </div>
      <div v-if="isLoading" class="text-center">
        <b-spinner label="Loading..."></b-spinner>
      </div>
      <form @submit="onSubmit" v-else>
        <b-form-input hidden v-model="itemId" readonly></b-form-input>
        <b-container fluid>
          <b-row class="my-1 mt-3 mb-3" v-if="!selectedItem">
            <b-col>
              <b-form-group>
                <Add-Item-Input @update-item="updateItem"></Add-Item-Input>
                <!-- <vue-bootstrap-typeahead
                  v-model="query"
                  placeholder="Start typing to search for items"
                  :data="getAllItems"
                  :serializer="item => item.name"
                  :minMatchingChars="1"
                  @hit="updateItem($event.id)"
                /> -->
              </b-form-group>
            </b-col>
          </b-row>

          <div v-else>
            <!-- Item -->
            <b-row class="my-1">
              <b-col sm="3">
                <b-form-group>
                  <label for="input-none">Item * </label>
                </b-form-group>
              </b-col>
              <b-col sm="8" cols="10">
                <b-form-group>
                  <b-form-input
                    v-model="orderLine.name"
                    required
                    readonly
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="1" cols="1">
                <b-button variant="danger" @click="removeItem('item')"
                  >x</b-button
                >
              </b-col>
            </b-row>

            <!-- Wall nr start -->
            <b-row class="my-1" v-if="selectedItem">
              <b-col sm="3">
                <b-form-group>
                  <label for="input-none">Wall nr *</label>
                </b-form-group>
              </b-col>
              <b-col sm="8">
                <b-form-group>
                  <b-form-input
                    v-model="orderLine.description"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <!-- Wall nr end -->

            <!-- quantity -->
            <b-row class="my-1 mt-1" v-if="selectedItem">
              <b-col sm="3">
                <label class="mt-2" for="input-invalid">Quantity * </label>
              </b-col>

              <b-col sm="8" v-if="customQty">
                <b-form-input
                  id="range-2"
                  v-model="qty"
                  required
                  type="number"
                ></b-form-input>
              </b-col>
              <b-col v-else>
                <b-button
                  variant="success"
                  class="mr-2 "
                  size="lg"
                  @click="setQty(1)"
                  >1</b-button
                >
                <b-button
                  variant="success"
                  class="mr-2"
                  size="lg"
                  @click="setQty(2)"
                  >2</b-button
                >
                <b-button
                  variant="success"
                  class="mr-2"
                  size="lg"
                  @click="setQty(3)"
                  >3</b-button
                >
                <b-button
                  variant="success"
                  class="mr-2"
                  size="lg"
                  @click="setQty(4)"
                  >4</b-button
                >
              </b-col>
            </b-row>
            <!-- quantity end -->

            <!-- Dimensions -->
            <b-row class="my-1 mt-4" v-if="selectedItem">
              <b-col sm="3">
                <label class="mt-2">Dimensions * </label>
              </b-col>
              <b-col sm="4">
                <b-form-input
                  id="input-invalid"
                  type="number"
                  class="mt-1"
                  required
                  v-model="height"
                  step="any"
                  placeholder="Enter height"
                ></b-form-input>
              </b-col>
              <b-col sm="4">
                <b-form-group class="mt-1">
                  <b-form-input
                    id="input-valid"
                    v-model="width"
                    required
                    type="number"
                    step="any"
                    placeholder="Enter width"
                  ></b-form-input>
                </b-form-group>
                <b-form-input
                  id="input-invalid"
                  step="any"
                  hidden
                  type="number"
                  v-model="orderLine.sqrMeter"
                  readonly
                ></b-form-input>
              </b-col>
            </b-row>
            <!-- Dimensions end -->

            <!-- Polish type -->
            <b-row
              class="my-1"
              v-if="
                getCurrentItem.polishType &&
                  getCurrentItem.polishType.length > 0 &&
                  selectedItem
              "
            >
              <b-col sm="3">
                <label for="polisingTypes">Polishing</label>
              </b-col>
              <b-col sm="8" cols="10">
                <b-form-select
                  v-model="orderLine.polishType"
                  :options="polishTypeOptions"
                ></b-form-select>
              </b-col>
              <b-col sm="1" cols="1">
                <b-button
                  variant="danger"
                  size="sm"
                  class="mt-1"
                  @click="removeItem('polishType')"
                  v-if="hasSelectedPolishType"
                  >x</b-button
                >
              </b-col>
            </b-row>
            <!-- Polish type end -->

            <b-row
              v-if="
                getCurrentItem.specialTxt &&
                  getCurrentItem.specialTxt.length > 0 &&
                  selectedItem &&
                  !addSpecialTxt
              "
              class="mt-3"
            >
              <b-col>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="addSpecialTxt"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="true"
                >
                  Special txt details.
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row
              class="my-1 mt-3"
              v-if="
                getCurrentItem.specialTxt &&
                  getCurrentItem.specialTxt.length > 0 &&
                  selectedItem &&
                  addSpecialTxt
              "
            >
              <b-col sm="3">
                <b-form-group>
                  <label for="specialTxtTypes">Special TXT</label>
                </b-form-group>
              </b-col>
              <b-col sm="8" cols="10">
                <b-form-group>
                  <b-form-select
                    v-model="orderLine.specialTxt"
                    :options="specialTxtOptions"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="1" cols="1">
                <b-button
                  variant="danger"
                  size="sm"
                  class="mt-1"
                  @click="removeItem('specialTxt')"
                  v-if="hasSelectedSpecialTxt"
                  >x</b-button
                >
              </b-col>
            </b-row>

            <Upload-Image
              :orderLine.sync="orderLine"
              :selectedItem.sync="selectedItem"
            ></Upload-Image>

            <b-row>
              <b-button variant="primary" type="submit" class="m-3"
                >Add</b-button
              >
              <b-button variant="danger" @click="hideModal" class="m-3"
                >Cancel</b-button
              >
            </b-row>
            <b-alert show variant="info">* (required fields)</b-alert>
          </div>
        </b-container>
      </form>
    </b-modal>
  </section>
  <!-- Modal END -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
// import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import UploadImage from "@/components/UploadImage";
import AddItemInput from "@/components/AddItemInput";

export default {
  name: "addItemModal",
  components: {
    UploadImage,
    AddItemInput
  },
  data() {
    return {
      isLoading: true,
      isLoadingItem: false,
      query: "",
      itemModalError: null,
      addSpecialTxtValue: false,
      errormsg: null,
      orderLine: {
        id: null,
        name: null,
        qty: 0,
        height: null,
        width: null,
        sqrMeter: null,
        description: "",
        specialTxt: null,
        polishType: null,
        attachmentUrl: null,
        attachmentUrl2: null,
        attachmentUrl3: null,
        attachmentUrl4: null
      }
    };
  },
  mounted() {
    this.loadItems();
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "updateCreateCurrentOrder",
      "addItemToOrder",
      "createNewOrderOnProject",
      "setCurrentOrderLine",
      "loadItems",
      "setNewFormItem"
    ]),
    setQty(qty) {
      this.orderLine.qty = qty;
    },
    hideModal() {
      //validates that all the required fields are added
      this.$bvModal.hide("addItemModal");
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["addItemModal"].toggle("#toggle-btn");
    },
    updateSquare() {
      if (
        this.orderLine.height > 0 &&
        this.orderLine.height > 0 &&
        this.orderLine.qty > 0
      ) {
        this.orderLine.sqrMeter =
          (this.orderLine.qty *
            (this.orderLine.height * this.orderLine.width)) /
          1000000;
      }
    },
    removeItem(name) {
      if (name === "polishType") {
        this.orderLine.polishType = "";
      } else if (name === "specialTxt") {
        this.orderLine.specialTxt = "";
      } else if (name === "item") {
        this.orderLine.name = "";
        this.orderLine.specialTxt = "";
        this.orderLine.polishType = "";
      }
    },
    onSubmit(evt) {
      evt.preventDefault();

      if (!this.getCurrentOrder.id) {
        this.createNewOrderOnProject(
          this.getCurrentProject,
          this.getCurrentOrder
        );
      }

      if (this.getCurrentOrder.id) {
        // order.orderLines.push(orderLine);
        if (this.orderLine) {
          var self = this;

          this.setCurrentOrderLine(self.orderLine)
            .then(() => {
              self.getCurrentOrderLine.id = null;
              delete this.getCurrentOrderLine.id;
              this.addItemToOrder(
                self.getCurrentOrder,
                self.getCurrentOrderLine
              )
                .then(() => {
                  this.$bvModal.hide("addItemModal");
                  //Resets local data
                  this.orderLine.id = null;
                  delete this.orderLine.id;
                  this.orderLine.qty = 0;
                  this.orderLine.height = null;
                  this.orderLine.width = null;
                  this.orderLine.sqrMeter = 0;
                  this.orderLine.description = "";
                  this.orderLine.specialTxt = "";
                  this.orderLine.polishType = "";
                  this.orderLine.attachmentUrl = null;
                  this.orderLine.attachmentUrl2 = null;
                  this.orderLine.attachmentUrl3 = null;
                  this.orderLine.attachmentUrl4 = null;
                })
                .catch(error => {
                  this.errormsg = error;
                });
            })
            .catch(err => {
              this.errormsg = err;
            });
        } else {
          this.errormsg = "Missing item in item modal";
        }
      }
    },
    updateItem(data) {
      this.isLoading = true;
      this.orderLine.specialTxt = null;
      this.orderLine.polishType = null;
      this.setNewFormItem(data.id).then(() => {
        this.orderLine.name = this.getCurrentItem.name;
        this.isLoading = false;
      });
      this.orderLine.itemId = data.id;
    }
  },
  computed: {
    ...mapGetters([
      "getAllItems",
      "getEditorOrderLineId",
      "getCurrentProject",
      "getCurrentOrder",
      "getCurrentOrderLine",
      "getUploadError",
      "getCurrentItem",
      "getAppSettings"
    ]),
    height: {
      get() {
        return this.orderLine.height;
      },
      set(value) {
        this.orderLine.height = value;
      }
    },
    width: {
      get() {
        return this.orderLine.width;
      },
      set(value) {
        this.orderLine.width = value;
      }
    },
    qty: {
      get() {
        return this.orderLine.qty;
      },
      set(value) {
        this.orderLine.qty = value;
      }
    },
    selectedItem: {
      get() {
        return this.orderLine.name;
      }
    },
    addSpecialTxt: {
      get() {
        return this.addSpecialTxtValue;
      },
      set(value) {
        this.addSpecialTxtValue = value;
      }
    },
    customQty: {
      get() {
        return this.orderLine.qty > 0 ? true : false;
      }
    },
    itemId: {
      get() {
        return this.orderLine.itemId;
      },
      set(value) {
        this.orderLine.itemId = value;
      }
    },
    specialTxtOptions: {
      get() {
        if (this.getCurrentItem.specialTxt) {
          return this.getCurrentItem.specialTxt.map(x => {
            return { value: x.name, text: x.name };
          });
        } else {
          return null;
        }
      }
    },
    polishTypeOptions: {
      get() {
        if (this.getCurrentItem.polishType) {
          return this.getCurrentItem.polishType.map(x => {
            return { value: x.name, text: x.name };
          });
        } else {
          return null;
        }
      }
    },
    hasSelectedSpecialTxt: {
      get() {
        return this.orderLine.specialTxt;
      }
    },
    hasSelectedPolishType: {
      get() {
        return this.orderLine.polishType;
      }
    },
    lineId: {
      get() {
        return this.getEditorOrderLineId;
      }
    }
  },
  watch: {
    height() {
      this.updateSquare();
    },
    width() {
      this.updateSquare();
    },
    qty() {
      this.updateSquare();
    },
    itemId() {
      if (
        this.orderLine.itemId &&
        this.orderLine.itemId !== "00000000-0000-0000-0000-000000000000"
      ) {
        this.setNewFormItem(this.orderLine.itemId);
      } else {
        this.setNewFormItem();
      }
    },
    lineId() {
      this.orderLine.id = null;
      delete this.orderLine.id;
      this.orderLine.qty = 0;
      this.orderLine.height = null;
      this.orderLine.width = null;
      this.orderLine.sqrMeter = 0;
      this.orderLine.description = "";
      this.orderLine.specialTxt = "";
      this.orderLine.polishType = "";
      this.orderline.attachmentUrl = "";
    }
  }
};
</script>

<style></style>
